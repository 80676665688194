<template>
    <div class="k-flex-col">
        <h4>Informations générales</h4>

        <b-form-group>
            <label> <b>Email</b></label>
            <div>{{ mail }}</div>
        </b-form-group>

        <b-form-group>
            <label><b>Niveau de droits</b></label>
            <div>
                <b-icon-person-fill /> {{ credentials | userRoleFromCreds }}
            </div>
        </b-form-group>

        <b-form-group>
            <label> <b>Nom</b> <span class="text-danger">*</span> </label>
            <b-form-input
                v-model="lastName"
                :state="lastName === user.lastName ? null : isLastNameValid"
                trim
            ></b-form-input>
            <b-form-invalid-feedback :state="isLastNameValid">
                Veuillez saisir votre nom
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label> <b>Prénom</b> <span class="text-danger">*</span> </label>
            <b-form-input
                v-model="firstName"
                :state="firstName === user.firstName ? null : isFirstNameValid"
                trim
            >
            </b-form-input>
            <b-form-invalid-feedback :state="isFirstNameValid">
                Veuillez saisir votre prénom
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label> <b>Numéro de téléphone</b> </label>
            <b-form-input v-model="phone" autocomplete="nope" trim>
            </b-form-input>
        </b-form-group>

        <b-button
            class="align-self-center"
            variant="kalkin-2"
            @click="updateMe()"
            :disabled="!hasUserChanged || !isUserValid"
        >
            <b-icon-check-circle /> Enregistrer les modifications
        </b-button>

        <h4>Modifier le mot de passe</h4>

        <b-form-group>
            <label><b>Ancien mot de passe</b></label>
            <b-form-input v-model="oldPassword" type="password"></b-form-input>
            <b-form-invalid-feedback :state="isOldPasswordValid">
                Veuillez saisir votre ancien mot de passe
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label><b>Nouveau mot de passe</b></label>
            <b-form-input
                v-model="newPassword"
                type="password"
                autocomplete="new-password"
            ></b-form-input>
            <b-form-invalid-feedback :state="isNewPasswordValid">
                Votre mot de passe doit contenir au moins 8 caractères
            </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
            <label><b>Confirmation du nouveau mot de passe</b></label>
            <b-form-input
                v-model="newPasswordConfirmation"
                type="password"
                autocomplete="nope"
            ></b-form-input>
            <b-form-invalid-feedback :state="isNewPasswordMatching">
                Les mots de passe ne correspondent pas
            </b-form-invalid-feedback>
        </b-form-group>

        <b-button
            class="align-self-center mb-2"
            variant="kalkin-2"
            @click="updatePassword()"
            :disabled="!hasPasswordChanged || !isPasswordValid"
        >
            <b-icon-check-circle /> Valider le nouveau mot de passe
        </b-button>
    </div>
</template>
<script>
import validator from "@/tools/validator";

export default {
    props: {
        user: { type: Object, required: TextTrackCue },
    },

    data: function() {
        return {
            mail: this.user.mail,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            phone: this.user.phone,
            credentials: this.user.credentials,
            oldPassword: "",
            newPassword: "",
            newPasswordConfirmation: "",
        };
    },

    computed: {
        isMailValid() {
            if (this.mail === null) {
                return null;
            } else {
                return validator.validateEmail(this.mail);
            }
        },

        isLastNameValid() {
            if (this.lastName === null) {
                return null;
            } else {
                return this.lastName !== "";
            }
        },

        isFirstNameValid() {
            if (this.firstName === null) {
                return null;
            } else {
                return this.firstName !== "";
            }
        },

        isUserValid() {
            return (
                this.isMailValid &&
                this.isLastNameValid &&
                this.isFirstNameValid
            );
        },

        hasUserChanged() {
            return (
                this.mail !== this.user.mail ||
                this.firstName !== this.user.firstName ||
                this.lastName !== this.user.lastName ||
                this.phone !== this.user.phone
            );
        },

        isOldPasswordValid() {
            return !this.hasPasswordChanged || this.oldPassword.length > 0;
        },

        isNewPasswordValid() {
            return this.newPassword.length === 0 || this.newPassword.length > 7;
        },

        isNewPasswordMatching() {
            return this.newPassword === this.newPasswordConfirmation;
        },

        isPasswordValid() {
            return (
                this.isOldPasswordValid &&
                this.isNewPasswordValid &&
                this.isNewPasswordMatching
            );
        },

        hasPasswordChanged() {
            return (
                this.newPassword.length > 0 ||
                this.newPasswordConfirmation.length > 0
            );
        },
    },

    methods: {
        async updateMe() {
            if (this.isUserValid) {
                this.$store.dispatch("user/updateMyProfile", {
                    lastName: this.lastName,
                    firstName: this.firstName,
                    phone: this.phone,
                });
            }
        },

        async updatePassword() {
            if (this.isPasswordValid) {
                this.$store.dispatch("user/updateMyPassword", {
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                });
            }
        },
    },
};
</script>
