<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Mon profil</h1>
        </div>
        <FormMe :user="user" />
    </div>
</template>
<script>
import FormMe from "@/components/model/user/FormMe.vue";

export default {
    components: {
        FormMe,
    },
    data: function() {
        return {
            user: this.$store.state.user.user,
        };
    },
};
</script>
